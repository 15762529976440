import { createApp } from "vue";
import { createPinia } from "pinia";
import "./globals.d";

import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueSweetalert2 from "vue-sweetalert2";
import Notifications from "@kyvg/vue3-notification";

// @ts-ignore
import VueCountryRegionSelect from "vue3-country-region-select";
//import "bootstrap";

import "maz-ui/css/main.css";
import "./assets/ui.dev.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "sweetalert2/dist/sweetalert2.min.css";

import "polyfill-object.fromentries";

const app = createApp(App);

// @ts-ignore
if (typeof Sentry == 'undefined') {
    window.Sentry = null;
}

router.afterEach(() => {
    document.body.scrollIntoView();
});

app.use(createPinia())
    .use(router)
    .use(i18n)
    .use(VueCountryRegionSelect)
    .use(VueSweetalert2)
    .use(Notifications);

app.mount("#app");
