import type { Router } from "vue-router";
import { useRouter } from "vue-router";
import type { PaymentReturnInterface } from "@/payment/PaymentReturnInterface";
import type { PaymentStore } from "@/stores/payment";
import { usePaymentStore } from "@/stores/payment";

class PaymentReturnHandler {
    private router: Router;
    private store: PaymentStore;

    constructor(router: Router, store: PaymentStore) {
        this.router = router;
        this.store = store;
    }

    public registerHandler(): void {
        if (!window.paymentReturnHandler) {
            window.paymentReturnHandler = this.handler.bind(this);
        }
    }

    public async handler(data: PaymentReturnInterface): Promise<void> {
        this.store.setPayment(data);
        if (data.isOk) {
            await this.router.push({ name: "payment-success" });
        } else if (data.isFailure) {
            await this.router.push({ name: "payment-failure" });
        } else {
            await this.router.push({ name: "payment-pending" });
        }
    }
}

export function createPaymentReturnHandler(): PaymentReturnHandler {
    return new PaymentReturnHandler(useRouter(), usePaymentStore());
}
