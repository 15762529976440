import { BaseApi } from "@/api/BaseApi";
import { baseUrl as confBaseUrl, itemsPerPage } from "@/config/globals";
import type { AxiosResponse } from "axios";
import axios from "axios";
import { ApplicationError } from "@/application/ApplicationError";
import type { LanguageInterface } from "@/application/LanguageInterface";

class ApplicationLanguageApi extends BaseApi {
    private appId: number | null = null;

    protected getBaseCollectionUrl(): string {
        if (!this.appId) {
            throw new ApplicationError(
                "Trying to generate url to language collection when appId is not set."
            );
        }
        return super.buildItemUrl(this.appId) + "/application-language";
    }

    protected buildItemUrl(id: number): string {
        return this.getBaseCollectionUrl() + "/" + id;
    }

    public async createLanguage(
        language: LanguageInterface
    ): Promise<LanguageInterface> {
        this.appId = language.application_id;
        const url = this.buildCollectionUrl(null);
        this.appId = null;
        const response: AxiosResponse<LanguageInterface> = await axios.post(
            url,
            language,
            {
                headers: await this.decorateHeadersWithAuthorization({}),
            }
        );
        return response.data;
    }

    public async updateLanguage(
        language: LanguageInterface
    ): Promise<LanguageInterface> {
        this.appId = language.application_id;
        const url = this.buildItemUrl(language.id as number);
        this.appId = null;
        const response: AxiosResponse<LanguageInterface> = await axios.patch(
            url,
            language,
            {
                headers: await this.decorateHeadersWithAuthorization({}),
            }
        );
        return response.data;
    }

    public async deleteLanguage(id: number, appId: number): Promise<void> {
        this.appId = appId;
        const url = this.buildItemUrl(id as number);
        this.appId = null;
        await axios.delete(url, {
            headers: await this.decorateHeadersWithAuthorization({}),
        });
    }
}

const baseUrl = confBaseUrl + "/application";
export const applicationLanguageApi = new ApplicationLanguageApi(
    baseUrl,
    itemsPerPage
);
