import type { Store } from "pinia";
import type { PaymentReturnInterface } from "@/payment/PaymentReturnInterface";
import { defineStore } from "pinia";

export type PaymentStore = Store<
    "payment",
    { payment: PaymentReturnInterface | null},
    {},
    {
        setPayment: (payment: PaymentReturnInterface) => void,
        reset: () => void
    }
>

export const usePaymentStore = defineStore('payment', {
    state() {
        return {
            payment: null as PaymentReturnInterface | null,
        };
    },
    actions: {
        setPayment(payment: PaymentReturnInterface): void {
            this.payment = payment;
        },
        reset(): void {
            this.payment = null;
        }
    }
})
