import { recaptchaKey } from "@/config/globals";

class Recaptcha {
    private readonly recaptchaKey: string;
    private readonly recaptchaLoaded: Promise<void>;

    constructor(recaptchaKey: string) {
        this.recaptchaKey = recaptchaKey;
        this.recaptchaLoaded = this.init();
    }

    private init(): Promise<void> {
        return new Promise<void>((resolve) => {
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js?render=" + this.recaptchaKey;
            script.async = true;
            // @ts-ignore
            script.onload = () => grecaptcha.ready(() => resolve());
            document.body.append(script);
        })
    }

    public async execute(action: string): Promise<string>
    {
        await this.recaptchaLoaded;
        // @ts-ignore
        return await grecaptcha.execute(this.recaptchaKey, { action: action });
    }
}

const recaptcha = {
    instance: null as Recaptcha | null,
};

export function useRecaptcha(): Recaptcha {
    if (!recaptcha.instance) {
        recaptcha.instance = new Recaptcha(recaptchaKey);
    }
    return recaptcha.instance;
}
