import { BaseApi } from "@/api/BaseApi";
import type { AxiosResponse } from "axios";
import type { UserInterface } from "@/user/UserInterface";
import axios from "axios";
import { baseUrl as confBaseUrl, itemsPerPage } from "@/config/globals";
import type { BasicResponseInterface } from "@/api/BasicResponseInterface";
import { LoginError } from "@/user/LoginError";
import { ApiError } from "@/api/ApiError";
import type { LoginResponseInterface } from "@/user/LoginResponseInterface";

class UserApi extends BaseApi {
    private rememberMe = false;

    public async register(user: UserInterface): Promise<UserInterface> {
        const url = this.buildCollectionUrl(null) + "/registration";
        const response = await axios.post(
            url,
            user,
            { headers: await this.decorateHeadersWithRecaptchaToken('registration', {}) }
        );
        return response.data;
    }

    private storeRefreshToken(token: string, rememberMe: boolean): void {
        localStorage.rememberMe = rememberMe;
        if (rememberMe) {
            localStorage.refreshToken = token;
        } else {
            sessionStorage.refreshToken = token;
        }
    }

    private loadRefreshToken(): string | null {
        this.rememberMe = localStorage.rememberMe || false;
        let token: string | null;
        token = localStorage.refreshToken || null;
        if (!token) {
            token = sessionStorage.refreshToken || null;
        }
        return token;
    }

    public async login(
        user: UserInterface,
        rememberMe: boolean
    ): Promise<LoginResponseInterface> {
        this.rememberMe = rememberMe;
        const url = this.buildCollectionUrl(null) + "/login";
        const loginForm = {
            Login: {
                login: user.email,
                password: user.password,
                rememberMe: rememberMe ? "1" : "0",
            },
        };
        const response: AxiosResponse<LoginResponseInterface> =
            await axios.post(url, loginForm, {
                maxRedirects: 0,
                headers: await this.decorateHeadersWithRecaptchaToken('login', {})
            });
        if (!response.data.success) {
            throw new LoginError(response.data.errors);
        }
        this.storeRefreshToken(response.data.refresh_token, rememberMe);
        return response.data;
    }

    public async fbLogin(
        token: string,
        rememberMe: boolean
    ): Promise<LoginResponseInterface> {
        this.rememberMe = rememberMe;
        const url = this.buildCollectionUrl(null) + "/fb-login";
        const response: AxiosResponse<LoginResponseInterface> =
            await axios.post(
                url,
                { token: token },
                {
                    maxRedirects: 0,
                }
            );
        if (!response.data.success) {
            throw new LoginError(response.data.errors);
        }
        this.storeRefreshToken(response.data.refresh_token, rememberMe);
        return response.data;
    }

    public async fbRegister(
        token: string,
        rememberMe: boolean
    ): Promise<LoginResponseInterface> {
        this.rememberMe = rememberMe;
        const url = this.buildCollectionUrl(null) + "/fb-registration";
        const response: AxiosResponse<LoginResponseInterface> =
            await axios.post(
                url,
                { token: token },
                {
                    maxRedirects: 0,
                }
            );
        this.storeRefreshToken(response.data.refresh_token, rememberMe);
        return response.data;
    }

    public forcedLogout(): void
    {
        this.throwAwayRefreshToken();
    }

    public async refresh(): Promise<LoginResponseInterface | null> {
        const refreshToken = this.loadRefreshToken();
        if (!refreshToken) {
            return null;
        }
        const url = this.buildCollectionUrl(null) + "/refresh";
        const response: AxiosResponse<LoginResponseInterface> =
            await axios.post(url, { token: refreshToken });
        if (!response.data.success) {
            if (response.data.errors.token) {
                this.throwAwayRefreshToken();
                return null;
            }
            throw new LoginError(response.data.errors);
        }
        this.storeRefreshToken(response.data.refresh_token, this.rememberMe);
        return response.data;
    }

    public async logout(): Promise<void> {
        const url = this.buildCollectionUrl(null) + "/logout";
        const response: AxiosResponse<BasicResponseInterface> =
            await axios.post(url);
        if (!response.data.success) {
            throw new ApiError();
        }
        this.throwAwayRefreshToken();
    }

    private throwAwayRefreshToken() {
        localStorage.removeItem("refreshToken");
        sessionStorage.removeItem("refreshToken");
    }

    public async updateMe(user: UserInterface): Promise<UserInterface> {
        const url = this.buildCollectionUrl(null) + '/me';
        const response: AxiosResponse<UserInterface> = await axios.post(
            url,
            {
                first_name: user.first_name,
                surname: user.surname,
            },
            { headers: await this.decorateHeadersWithAuthorization({})}
        );
        return response.data;
    }
}

const baseUrl = confBaseUrl + "/user";
export const userApi = new UserApi(baseUrl, itemsPerPage);
