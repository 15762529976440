<template>
    <div class="container">
        <div class="row index-tiles pt-3">
            <div class="col-12 col-lg-8 offset-lg-2">
                <h1>{{ $t("home.h1")}}</h1>

                <p>{{ $t("home.text1")}}</p>
                <p><b>{{ $t("home.text2")}}</b></p>
                <ul>
                    <li>{{ $t("home.text3")}}</li>
                    <li>{{ $t("home.text4")}}</li>
                    <li>{{ $t("home.text5")}}</li>
                    <li>{{ $t("home.documentPointCV")}}</li>
                    <li>
                        {{ $t("home.text6")}}
                        <Popper :hover="true">
                            <div>
                                <img src="/images/icon-hint.png" class="me-2" alt="Question mark">
                                <span class="tooltip-trigger">
                                    {{ $t("home.showDocuments") }}
                                </span>
                            </div>
                            <template #content>
                                <ul>
                                    <li>{{ $t("home.tooltip1")}}</li>
                                    <li>{{ $t("home.tooltip2")}}</li>
                                    <li>{{ $t("home.tooltip3")}}</li>
                                </ul>
                            </template>
                        </Popper>
                    </li>
                </ul>
                <p>{{ $t("home.text7")}}<br>
                  {{$t("home.textFeeNotRequiredForSomePrograms")}}
                </p>

                <div v-if="isLoggedIn">
                    <RouterLink
                        :to="routeByApplication"
                        class="btn btn-yellow btn-lg"
                        v-if="application?.id"
                    >{{ $t('home.continueToExistingApplication')}}</RouterLink
                    >
                    <RouterLink
                        :to="{ name: 'application-new' }"
                        class="btn btn-yellow btn-lg"
                        v-else
                    >{{ $t('home.continueTo1stStep')}}</RouterLink
                    >
                </div>
                <div v-else>
                    <div class="row justify-content-center pt-3">
                        <div class="col-auto mb-3">
                            <p class="mb-1">{{$t('home.btn1p')}}</p>
                            <RouterLink to="/register" class="btn btn-yellow btn-lg">{{$t('home.btn1')}}</RouterLink>
                        </div>
                        <div class="col-auto">
                            <p class="mb-1">{{$t('home.btn2p')}}</p>
                            <RouterLink to="/login" class="btn btn-primary btn-lg">{{$t('home.btn2')}}</RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { useUserStore } from "@/stores/user";
import { mapState } from "pinia";
import Popper from "vue3-popper";
import type { ApplicationInterface } from "@/application/ApplicationInterface";
import { useApplicationStore } from "@/stores/application";

export default defineComponent({
    name: "HomeView",
    components: {
        Popper
    },
    data() {
        return {
            isLoggedIn: false,
            application: null as ApplicationInterface | null,
        };
    },
    setup() {
        // @ts-ignore
        const { setPageTitle } = inject<{ setPageTitle: (title: string) => void }>('pageTitle');
        return { setPageTitle };
    },
    async mounted(): Promise<void> {
        const userStore = useUserStore();
        const token = await userStore.validToken();
        this.isLoggedIn = token !== null;
        this.application = await useApplicationStore().fetchOpen();
        this.setPageTitle(this.$t("titles.home"));
    },
    computed: {
        ...mapState(useUserStore, ["token"]),
        routeByApplication(): object {
            if (!this.application) {
                return { name: 'application-new' };
            }

            if (this.application.reviewed_at) {
                return { name: 'application-review', params: { id: this.application.id }};
            }

            if (this.application.personal_data_submitted_at) {
                return { name: 'application-documents', params: { id: this.application.id }};
            }

            return { name: "application-personal", params: { id : this.application.id }};
        }
    },
    watch: {
        token(): void {
            this.isLoggedIn = this.token !== null;
        },
    },
});
</script>
