import { createI18n } from "vue-i18n";
import enUs from "./locales/en-US.json";
import csCZ from "./locales/cs-CZ.json";

type MessageSchema = typeof enUs;

export default createI18n<[MessageSchema], "en-US" | "cs-CZ">({
    legacy: false,
    locale: import.meta.env.VITE_I18N_LOCALE || "en-US",
    fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || "en-US",
    missingWarn: false,
    fallbackWarn: false,
    messages: {
        "en-US": enUs,
        "cs-CZ": csCZ,
    },
});
