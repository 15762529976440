import { BaseApi } from "@/api/BaseApi";
import { baseUrl as confBaseUrl, itemsPerPage } from "@/config/globals";
import type { ApplicationInterface } from "@/application/ApplicationInterface";
import type { ApplicationInterface as ApiApplicationInterface } from "@/application/api/ApplicationInterface";
import type { AxiosResponse } from "axios";
import axios, { AxiosError } from "axios";
import { applicationFromApi, applicationToApi } from "@/application/DataTransformation";
import type { ListResponseInterface } from "@/api/ListResponseInterface";
// @ts-ignore
import { sha1 } from "object-hash";
import { Mutex } from "async-mutex";

class ApplicationApi extends BaseApi {
    private myOnly = false;
    private partialSaveTimer: any = null;
    private partialSaveApplication: ApplicationInterface | null = null;
    private lastPartialHash: string | null = null;

    private saveMutex: Mutex;

    constructor(baseUrl: string, itemsPerPage: number) {
        super(baseUrl, itemsPerPage);
        this.saveMutex = new Mutex()
    }

    public schedulePartialSave(application: ApplicationInterface): void
    {
        if (
            application.personal_data_submitted_at
        ) {
            // partial save is only available until personal data was submitted
            return;
        }

        this.cancelPartialSave();
        this.partialSaveApplication = application;
        this.partialSaveTimer = setTimeout(this.partialSaveHandler.bind(this), 5000);
    }

    private applicationHash(application: ApplicationInterface): string
    {
        const app = {
            ...application
        };
        app.version = 0;
        return sha1(applicationToApi(app));
    }

    private cancelPartialSave(): void
    {
        if (!this.partialSaveTimer) {
            return;
        }
        clearTimeout(this.partialSaveTimer);
        this.partialSaveTimer = null;
        this.partialSaveApplication = null;
    }

    private async partialSaveHandler(): Promise<void> {
        if (!this.partialSaveApplication) {
            this.partialSaveTimer = null;
            return;
        }
        const appHash = this.applicationHash(this.partialSaveApplication);
        if (this.lastPartialHash === appHash ) {
            return;
        }
        this.lastPartialHash = appHash;

        if (this.saveMutex.isLocked()) {
            return;
        }
        const release = await this.saveMutex.acquire();
        try {
            const toSave = applicationToApi(this.partialSaveApplication);
            const url = this.buildItemUrl(this.partialSaveApplication.id as number) + "/partial";

            const response = await axios.patch(url, toSave, {
                headers: await this.decorateHeadersWithAuthorization({}),
            });
            this.partialSaveApplication.version = response.data.version;
        } catch (e) {
            window.Sentry?.captureException(e);
        }

        release();
        this.partialSaveApplication = null;
        this.partialSaveTimer = null;
    }

    public async findOpen(): Promise<ApplicationInterface | null> {
        const url = this.buildCollectionUrl(null) + "/open";
        return await this.load(url);
    }

    protected getBaseCollectionUrl(): string {
        const base = super.getBaseCollectionUrl();
        if (this.myOnly) {
            return base + "/my";
        }

        return base;
    }

    public async loadMy(): Promise<ApplicationInterface[]> {
        this.myOnly = true;
        const applications = [] as ApplicationInterface[];
        let page: number = 0;
        let hasNextPage = true;
        while (hasNextPage) {
            page++;
            const url: string | null = this.buildCollectionUrl(page);
            const response: AxiosResponse<
                ListResponseInterface<ApiApplicationInterface>
                > = await axios.get(url, {
                headers: await this.decorateHeadersWithAuthorization()
            });
            for (const item of response.data.items) {
                applications.push(applicationFromApi(item));
            }
            hasNextPage = response.data._meta.pageCount > page;
        }
        this.myOnly = false;

        return applications;
    }

    public async saveProgramSelection(
        data: ApplicationInterface
    ): Promise<ApplicationInterface> {
        const toSave = {
            program_id: data.program_id,
            term_id: data.term_id,
        } as ApplicationInterface;
        if (data.id) {
            toSave.id = data.id;
            toSave.version = data.version;
        }

        let response: AxiosResponse<ApiApplicationInterface>;
        if (toSave.id) {
            const url = this.buildItemUrl(toSave.id);
            response = await axios.patch(url, toSave, {
                headers: await this.decorateHeadersWithAuthorization(),
            });
        } else {
            const url = this.buildCollectionUrl(null);
            response = await axios.post(url, toSave, {
                headers: await this.decorateHeadersWithAuthorization(),
            });
        }

        return applicationFromApi(response.data);
    }

    async findById(appId: number): Promise<ApplicationInterface | null> {
        const url = this.buildItemUrl(appId);
        return await this.load(url);
    }

    private async load(url: string) {
        try {
            const response: AxiosResponse<ApiApplicationInterface> =
                await axios.get(url, {
                    headers: await this.decorateHeadersWithAuthorization(),
                });
            return applicationFromApi(response.data);
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response && error.response.status == 404) {
                    return null;
                }
            } else {
                throw error;
            }
        }

        return null;
    }

    async savePersonal(
        application: ApplicationInterface
    ): Promise<ApplicationInterface> {
        const release = await this.saveMutex.acquire();
        try {
            this.cancelPartialSave();
            const toSave = applicationToApi(application);
            const url = this.buildItemUrl(application.id as number) + "/personal";
            const response = await axios.patch(url, toSave, {
                headers: await this.decorateHeadersWithAuthorization({}),
            });
            return response.data;
        } finally {
            release();
        }
    }

    async markReviewed(application: ApplicationInterface): Promise<void> {
        const url =
            this.buildItemUrl(application.id as number) + "/mark-reviewed";
        await axios.patch(
            url,
            {},
            {
                headers: await this.decorateHeadersWithAuthorization({}),
            }
        );
    }
}

const baseUrl = confBaseUrl + "/application";
export const applicationApi = new ApplicationApi(baseUrl, itemsPerPage);
