import type { Router } from "vue-router";
import { AxiosError } from "axios";
import Swal from "sweetalert2";
import { useUserStore } from "@/stores/user";

export interface ErrorStrings {
    unauthorized: string;
    generic: string;
}

class ErrorHandler {
    public strings: ErrorStrings = {
        unauthorized: "",
        generic: "",
    };
    private router: Router|null = null;

    public setStrings(value: ErrorStrings): void {
        this.strings = value;
    }

    public handle(err: any): void {
        window.Sentry?.captureException(err);
        if (
            err instanceof AxiosError
            && err.response?.status == 401
        ) {
            this.handleUnauthorized();
        } else {
            Swal.fire(this.strings.generic, "", "warning");
        }
    }

    public handleAxiosError(
        err: any,
        swalTitle: string,
        swalBody: string = "",
        contexts: any = {},
    ): void {
        if (!(err instanceof AxiosError)) {
            return;
        }

        if (err.response?.status == 401) {
            this.handleUnauthorized();
        } else {
            const sentryContexts: any = {
                contexts: contexts,
            };
            if (err.response) {
                sentryContexts.contexts.response = {
                    status: err.response.status,
                    statusText: err.response.statusText,
                    body: err.response.data,
                }
            }
            window.Sentry?.captureException(err, sentryContexts);
            // @ts-ignore
            Swal.fire(swalTitle, swalBody, "error");
        }
    }

    private handleUnauthorized(): void {
        Swal.fire(
            this.strings.unauthorized,
            "",
            "warning"
        ).then(() => {
            useUserStore().reset();
            this.router?.push({name: "login"});
        });
    }

    setRouter(router: Router) {
        this.router = router;
    }
}

export const errorHandler = new ErrorHandler();
