import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useApplicationStore } from "@/stores/application";
import type { ApplicationInterface } from "@/application/ApplicationInterface";

async function checkLoggedIn(next: NavigationGuardNext): Promise<boolean> {
    const userStore = useUserStore();
    if (userStore.isLoggedIn) {
        return true;
    }

    const token = await userStore.validToken();
    if (token) {
        return true;
    }

    next({ name: "home" });
    return false;
}

export function userLoggedIn(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
): void {
    checkLoggedIn(next).then((success: boolean) => {
        if (!success) {
            return;
        }

        next();
    });
}

export function newApplicationGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
): void {
    checkLoggedIn(next).then((success: boolean) => {
        if (!success) {
            return;
        }

        const applicationStore = useApplicationStore();
        applicationStore.fetchOpen().then(
            (application) => {
                if (application && application.id) {
                    next({
                        name: "application-program",
                        params: { id: application.id },
                    });
                } else {
                    next();
                }
            },
            () => {
                next();
            }
        );
    });
}

export function notLockedApplicationGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
): void {
    checkLoggedIn(next).then((success: boolean) => {
        if (!success) {
            return;
        }

        const applicationStore = useApplicationStore();
        applicationStore
            .ensureApplication(parseInt(to.params.id as string))
            .then(
                (application: ApplicationInterface) => {
                    if (application.is_locked) {
                        next({
                            name: "application-view",
                            params: {
                                id: application.id
                            }
                        });
                    } else {
                        next();
                    }
                },
                () => {
                    next({
                        name: "home",
                    });
                }
            );
    });
}

export function existingApplicationGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
): void {
    checkLoggedIn(next).then((success: boolean) => {
        if (!success) {
            return;
        }

        const applicationStore = useApplicationStore();
        applicationStore
            .ensureApplication(parseInt(to.params.id as string))
            .then(
                () => {
                    next();
                },
                () => {
                    next({
                        name: "not-found",
                    });
                }
            );
    });
}
