class DateHelper {
    public toDateString(date: Date): string
    {
        return date.getFullYear() + "-"
            + this.pad(date.getMonth() + 1) + "-"
            + this.pad(date.getDate());
    }

    public fromDateString(date: string | null | undefined): Date | undefined
    {
        if (date) {
            if (date.indexOf(':') !== -1) {
                return new Date(date as string)
            }

            return new Date((date as string) + ' 00:00:00');
        }

        return undefined;
    }

    private pad(num: number): string
    {
        let result = num.toString();
        while (result.length < 2) {
            result = "0" + result;
        }

        return result;
    }
}

export const dateHelper = new DateHelper();
