function is(className: string, object: any) {
    return Object.prototype.toString.call(object) === '[object '+ className +']';
}

class DataEncoder {
    private levels: any[] = [];
    private actualKey: any = null;

    __dataEncoding(data: any): string {
        let uriPart = '';
        const levelsSize = this.levels.length;
        if (levelsSize) {
            uriPart = this.levels[0];
            for(let c = 1; c < levelsSize; c++) {
                uriPart += '[' + this.levels[c] + ']';
            }
        }
        let finalString = '';
        if (is('Object', data)) {
            const keys = Object.keys(data);
            const l = keys.length;
            for(let a = 0; a < l; a++) {
                const key = keys[a];
                let value = data[key];
                this.actualKey = key;
                this.levels.push(this.actualKey);
                finalString += this.__dataEncoding(value);
            }
        } else if (is('Array', data)) {
            if (!this.actualKey) throw new Error("Directly passed array does not work")
            const aSize = data.length;
            for (let b = 0; b < aSize; b++) {
                let aVal = data[b];
                this.levels.push(b);
                finalString += this.__dataEncoding(aVal);
            }
        } else {
            finalString += uriPart + '=' + encodeURIComponent(data) + '&';
        }
        this.levels.pop();
        return finalString;
    }

    encode(data: any): string | null {
        if (!is('Object', data) || !Object.keys(data).length) return null;
        return this.__dataEncoding(data).slice(0, -1);
    }
}

export const dataEncoder = new DataEncoder();
