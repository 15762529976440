import { defineStore } from "pinia";
import type { UserInterface } from "@/user/UserInterface";
import { userApi } from "@/user/UserApi";
import type { LoginResponseInterface } from "@/user/LoginResponseInterface";
import { decodeJwt } from "jose";
import { useApplicationStore } from "@/stores/application";
import { usePaymentStore } from "@/stores/payment";
import { Mutex } from "async-mutex";

const tokenMutex = new Mutex();

export const useUserStore = defineStore("user", {
    state: () => {
        return {
            user: null as UserInterface | null,
            roles: [] as string[],
            token: null as string | null,
            expire: null as Date | null,
        };
    },
    getters: {
        isLoggedIn: (state) => state.token !== null,
        isAdmission: (state) => state.roles.indexOf('admin') > -1 || state.roles.indexOf('admission') > -1,
    },
    actions: {
        async login(user: UserInterface, remember: boolean): Promise<void> {
            this.user = null;
            const response: LoginResponseInterface = await userApi.login(
                user,
                remember
            );
            user.password = "";
            this.setToken(response.token);
            this.expire = new Date(response.expire * 1000);
        },
        async fbLogin(fbToken: string, remember: boolean): Promise<void> {
            this.user = null;
            const response: LoginResponseInterface = await userApi.fbLogin(
                fbToken,
                remember
            );
            this.setToken(response.token);
            this.expire = new Date(response.expire * 1000);
        },
        async register(user: UserInterface): Promise<void> {
            await userApi.register(user);
        },
        async fbRegister(fbToken: string): Promise<void> {
            this.user = null;
            const response: LoginResponseInterface = await userApi.fbRegister(
                fbToken,
                false
            );
            this.setToken(response.token);
            this.expire = new Date(response.expire * 1000);
        },
        async logout(): Promise<void> {
            await userApi.logout();
            this.reset();
        },
        reset(): void {
            this.user = null;
            this.token = null;
            this.expire = null;
            this.roles = [];
            useApplicationStore().logout();
            usePaymentStore().reset();
        },
        async validToken(): Promise<string | null> {
            const release = await tokenMutex.acquire();
            const now = new Date();
            if (
                this.token !== null &&
                this.expire !== null &&
                this.expire > now
            ) {
                release();
                return this.token;
            }

            try {
                const refreshResponse: null | LoginResponseInterface =
                    await userApi.refresh();
                if (refreshResponse) {
                    this.setToken(refreshResponse.token);
                    this.expire = new Date(refreshResponse.expire * 1000);
                }
            } finally {
                release();
            }

            return this.token;
        },
        setToken(token: string): void {
            this.token = token;
            if(!this.user) {
                const decoded = decodeJwt(token);
                this.user = {
                    confirmPassword: undefined,
                    password: "",
                    policyAgreement: undefined,
                    email: decoded.email as string,
                    first_name: decoded.given_name as string,
                    surname: decoded.family_name as string
                }
                this.roles = decoded.roles as string[];
            }
        }
    },
});
