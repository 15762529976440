export const baseUrl = import.meta.env.DEV ? import.meta.env.VITE_API_URL + "/api": "/api";
export const appUrl = import.meta.env.DEV ? import.meta.env.VITE_API_URL : "";
export const recaptchaKey = import.meta.env.VITE_RECAPTCHA_KEY;
export const itemsPerPage = 50;
export const fbAppId = import.meta.env.VITE_FB_APPID;
export const fbApiVersion = import.meta.env.VITE_FB_VERSION;
export const paymentWindow = {
    // if window.innerWidth is less than limit, the user will be redirected instead of opening window
    widthLimit: 600,
    // if window.innerHeight is less than limit, the user will be redirected instead of opening window
    heightLimit: 400,
    // the width/height of new window are calculated as Math.max(window.innerWidth / coefficient, minWidth)
    widthCoefficient: 1.5,
    heightCoefficient: 1.5,
    minWidth: 400,
    minHeight: 300,
    // The minimal value for "left" argument when opening window
    minLeft: 400,
    // The minimal value for "top" argument when opening window
    minTop: 200,
}

export const allowedFileTypes = [
    'image',
];
export const allowedFileExtensions = [
    'pdf',
    'docx',
    'doc',
    'xlsx',
    'xls',
    'ppt',
    'pptx',
    'odt',
    'ods',
    'odf',
    'txt',
    'jpg',
    'jpeg',
    'png',
    'gif',
    'webp',
    'tiff',
    'heic'
];

export const allowedFileSize = 20;
