import { useUserStore } from "@/stores/user";
import type { AxiosRequestHeaders } from "axios";
import { useRecaptcha } from "@/recaptcha/Recaptcha";
import { dataEncoder } from "@/api/DataEncoder";

type filterType =
    | string
    | string[][]
    | Record<string, string>
    | URLSearchParams;

export class BaseApi {
    private readonly baseUrl: string;
    private readonly itemsPerPage: number;

    constructor(baseUrl: string, itemsPerPage: number) {
        this.baseUrl = baseUrl;
        this.itemsPerPage = itemsPerPage;
    }

    protected getBaseCollectionUrl(): string {
        return this.baseUrl;
    }

    protected getBaseItemUrl(): string {
        return this.baseUrl;
    }

    protected buildCollectionUrl(
        page: number | null,
        filter: filterType | null = null
    ): string {
        let url = this.getBaseCollectionUrl();
        let querySeparator = "?";
        if (page) {
            url += querySeparator + "per-page=" + this.itemsPerPage;
            querySeparator = "&";
            if (page > 1) {
                url += querySeparator + "page=" + page;
            }
        }
        if (filter) {
            url += querySeparator + encodeURI(dataEncoder.encode({filter: filter}) as string);
        }

        return url;
    }

    protected buildItemUrl(id: number): string {
        return this.getBaseItemUrl() + "/" + id;
    }

    protected async getToken(): Promise<string | null> {
        const userStore = useUserStore();
        return await userStore.validToken();
    }

    protected async decorateHeadersWithAuthorization(
        headers: AxiosRequestHeaders = {}
    ): Promise<AxiosRequestHeaders> {
        const token = await this.getToken();
        if (!headers.Authorization && token) {
            headers.Authorization = "Bearer " + token;
        }
        return headers;
    }

    protected async decorateHeadersWithRecaptchaToken(
        action: string,
        headers: AxiosRequestHeaders = {}
    ): Promise<AxiosRequestHeaders> {
        try {
            const token = await useRecaptcha().execute(action);
            if (!headers['X-Recaptcha-Token']) {
                headers['X-Recaptcha-Token'] = token;
            }
        } catch (e) {
            window.Sentry?.captureException(e);
        }
        return headers;
    }
}
