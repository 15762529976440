import { defineStore } from "pinia";

export const useCsrfStore = defineStore("csrf", {
    state: () => {
        return {
            token: null as string | null,
        };
    },
    actions: {
        setToken(token: string): void {
            this.token = token;
        },
    },
});
