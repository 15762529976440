import type { ApplicationInterface } from "@/application/ApplicationInterface";
import type { ApplicationInterface as ApiApplicationInterface } from "@/application/api/ApplicationInterface";
import { dateHelper } from "@/application/DateHelper";

export function applicationFromApi(data: ApiApplicationInterface): ApplicationInterface {
    return {
        id: data.id,
        program_id: data.program_id,
        term_id: data.term_id,
        user_id: data.user_id,
        heard_from: data.heard_from,
        //student's personal info
        student_first_name: data.student_first_name,
        student_middle_name: data.student_middle_name,
        student_surname: data.student_surname,
        student_gender: data.student_gender,
        student_birth: dateHelper.fromDateString(data.student_birth),
        student_birth_city: data.student_birth_city,
        student_birth_country: data.student_birth_country,
        student_nationality: data.student_nationality,
        student_citizenship: data.student_citizenship,
        student_special_needs: data.student_special_needs,
        // student's ID
        student_id_number: data.student_id_number,
        student_birth_number: data.student_birth_number,
        student_passport_number: data.student_passport_number,
        student_passport_expiration: dateHelper.fromDateString(data.student_passport_expiration),
        // contact address
        contact_email: data.contact_email,
        contact_mobile: data.contact_mobile ? data.contact_mobile : undefined,
        contact_address1: data.contact_address1,
        contact_address2: data.contact_address2,
        contact_zipcode: data.contact_zipcode,
        contact_country: data.contact_country,
        contact_as_permanent: data.contact_as_permanent,
        // permanent address
        permanent_address1: data.permanent_address1,
        permanent_address2: data.permanent_address2,
        permanent_zipcode: data.permanent_zipcode,
        permanent_country: data.permanent_country,
        // guardian or parent
        guardian_first_name: data.guardian_first_name,
        guardian_surname: data.guardian_surname,
        guardian_relationship: data.guardian_relationship,
        guardian_email: data.guardian_email,
        guardian_mobile: data.guardian_mobile ? data.guardian_mobile : undefined,
        guardian_address1: data.guardian_address1,
        guardian_address2: data.guardian_address2,
        guardian_zipcode: data.guardian_zipcode,
        guardian_country: data.guardian_country,
        // emergency contact
        emergency_use_guardian: data.emergency_use_guardian,
        emergency_first_name: data.emergency_first_name,
        emergency_surname: data.emergency_surname,
        emergency_email: data.emergency_email,
        emergency_mobile: data.emergency_mobile ? data.emergency_mobile : undefined,

        // student's language
        native_language: data.native_language ? data.native_language : null,
        languages: data.languages,

        //education
        educations: data.educations,

        //documents
        documents: data.documents,

        program: data.program,
        term: data.term,

        // application info
        created_at: data.created_at ? new Date(data.created_at as string) : undefined,
        updated_at: data.updated_at ? new Date(data.updated_at as string) : undefined,
        personal_data_submitted_at: data.personal_data_submitted_at,
        reviewed_at: data.reviewed_at,
        paid_at: data.paid_at,
        anonymised_at: data.anonymised_at,
        version: data.version,
        is_locked: data.is_locked,
        status: data.status,
        is_submitted: data.is_submitted,
        need_payment: data.need_payment,
    };
}

function toIsoString(data: Date | null | undefined): string | null
{
    if (!data) {
        return null;
    }

    try {
        return data.toISOString();
    } catch (e) {
        window.Sentry?.captureException(e, {
            contexts: {
                'param': data,
            }
        });
    }

    return null;
}

export function applicationToApi(data: ApplicationInterface): ApiApplicationInterface {
    return {
        id: data.id,
        program_id: data.program_id,
        term_id: data.term_id,
        user_id: data.user_id,
        heard_from: data.heard_from,
        //student's personal info
        student_first_name: data.student_first_name,
        student_middle_name: data.student_middle_name,
        student_surname: data.student_surname,
        student_gender: data.student_gender,
        student_birth: data.student_birth ? dateHelper.toDateString(data.student_birth) : null,
        student_birth_city: data.student_birth_city,
        student_birth_country: data.student_birth_country,
        student_nationality: data.student_nationality,
        student_citizenship: data.student_citizenship,
        student_special_needs: data.student_special_needs,
        // student's ID
        student_id_number: data.student_id_number,
        student_birth_number: data.student_birth_number,
        student_passport_number: data.student_passport_number,
        student_passport_expiration: data.student_passport_expiration ? dateHelper.toDateString(data.student_passport_expiration) : null,
        // contact address
        contact_email: data.contact_email,
        contact_mobile: data.contact_mobile ? data.contact_mobile : null,
        contact_address1: data.contact_address1,
        contact_address2: data.contact_address2,
        contact_zipcode: data.contact_zipcode,
        contact_country: data.contact_country,
        contact_as_permanent: data.contact_as_permanent,
        // permanent address
        permanent_address1: data.permanent_address1,
        permanent_address2: data.permanent_address2,
        permanent_zipcode: data.permanent_zipcode,
        permanent_country: data.permanent_country,
        // guardian or parent
        guardian_first_name: data.guardian_first_name,
        guardian_surname: data.guardian_surname,
        guardian_relationship: data.guardian_relationship,
        guardian_email: data.guardian_email,
        guardian_mobile: data.guardian_mobile ? data.guardian_mobile : null,
        guardian_address1: data.guardian_address1,
        guardian_address2: data.guardian_address2,
        guardian_zipcode: data.guardian_zipcode,
        guardian_country: data.guardian_country,
        // emergency contact
        emergency_use_guardian: data.emergency_use_guardian,
        emergency_first_name: data.emergency_first_name,
        emergency_surname: data.emergency_surname,
        emergency_email: data.emergency_email,
        emergency_mobile: data.emergency_mobile ? data.emergency_mobile : null,

        // student's language
        native_language: data.native_language,
        languages: data.languages,

        //education
        educations: data.educations,

        //documents
        documents: data.documents,

        program: data.program,
        term: data.term,

        // application info
        created_at: toIsoString(data.created_at),
        updated_at: toIsoString(data.updated_at),
        personal_data_submitted_at: data.personal_data_submitted_at,
        reviewed_at: data.reviewed_at,
        paid_at: data.paid_at,
        anonymised_at: data.anonymised_at,
        version: data.version,
        is_locked: data.is_locked,
        status: data.status,
        is_submitted: data.is_submitted,
        need_payment: data.need_payment,
    };
}

export function sentryAnonymizeApplication(app: ApplicationInterface): void
{
    const phoneNumberRegex = /^([+0-9 ]{4}).*([0-9])$/;
    const anonText = 'anonymized';

    if (app.student_first_name) {
        app.student_first_name = anonText;
    }
    if (app.student_middle_name) {
        app.student_middle_name = anonText;
    }
    if (app.student_surname) {
        app.student_surname = anonText;
    }
    if (app.student_id_number) {
        app.student_id_number = anonText;
    }
    if (app.student_birth_number) {
        app.student_birth_number = anonText;
    }
    if (app.student_passport_number) {
        app.student_passport_number = anonText;
    }
    if (app.student_surname) {
        app.student_surname = anonText;
    }
    if (app.contact_mobile) {
        app.contact_mobile = app.contact_mobile.replace(phoneNumberRegex, '$1***$2');
    }
    if (app.contact_email) {
        app.contact_email = anonText;
    }
    if (app.contact_address1) {
        app.contact_address1 = anonText;
    }
    if (app.guardian_first_name) {
        app.guardian_first_name = anonText;
    }
    if (app.guardian_surname) {
        app.guardian_surname = anonText;
    }
    if (app.guardian_mobile) {
        app.guardian_mobile = app.guardian_mobile.replace(phoneNumberRegex, '$1***$2');
    }
    if (app.guardian_email) {
        app.guardian_email = anonText;
    }
    if (app.guardian_address1) {
        app.guardian_address1 = anonText;
    }
    if (app.emergency_first_name) {
        app.emergency_first_name = anonText;
    }
    if (app.emergency_surname) {
        app.emergency_surname = anonText;
    }
    if (app.emergency_mobile) {
        app.emergency_mobile = app.emergency_mobile.replace(phoneNumberRegex, '$1***$2');
    }
    if (app.emergency_email) {
        app.emergency_email = anonText;
    }
    if (app.documents && app.documents.length > 0) {
        app.documents = [];
    }
}
