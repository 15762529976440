import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import {
    existingApplicationGuard,
    newApplicationGuard,
    notLockedApplicationGuard,
    userLoggedIn
} from "@/router/guards";

const router = createRouter({
    history: createWebHistory(import.meta.env.DEV ? "/vue/front/" : "/"),
    routes: [
        {
            path: "/",
            name: "home",
            component: HomeView,
        },
        {
            path: "/login",
            name: "login",
            component: () => import("../views/UserLoginView.vue"),
        },
        {
            path: "/register",
            name: "register",
            component: () => import("../views/UserRegisterView.vue"),
        },
        {
            path: "/forgotten-password",
            name: "forgotten-password",
            component: () => import("../views/ForgottenPasswordView.vue"),
        },
        {
            path: "/account",
            component: () => import("../views/AccountView.vue"),
            beforeEnter: userLoggedIn,
            children: [
                {
                    path: "",
                    name: "my-account",
                    component: () => import("../views/account/DefaultView.vue"),
                },
                {
                    path: "change-password",
                    name: "change-password",
                    component: () => import("../views/account/ChangePasswordView.vue"),
                },
                {
                    path: "remove",
                    name: "account-remove",
                    component: () => import("../views/account/RemoveView.vue"),
                },
                {
                    path: "my-applications",
                    name: "my-applications",
                    component: () => import("../views/account/ApplicationListView.vue"),
                }
            ]
        },
        {
            path: "/application/study-program",
            name: "application-new",
            component: () => import("../views/StudyProgramView.vue"),
            beforeEnter: newApplicationGuard,
        },
        {
            path: "/application/:id?",
            name: "application-view",
            component: () => import("../views/ApplicationView.vue"),
            beforeEnter: existingApplicationGuard,
        },
        {
            path: "/application/:id?/study-program",
            name: "application-program",
            component: () => import("../views/StudyProgramView.vue"),
            beforeEnter: notLockedApplicationGuard,
        },
        {
            path: "/application/:id?/personal-information",
            name: "application-personal",
            component: () => import("../views/PersonalInfoView.vue"),
            beforeEnter: notLockedApplicationGuard,
        },
        {
            path: "/application/:id?/documents",
            name: "application-documents",
            component: () => import("../views/DocumentsView.vue"),
            beforeEnter: notLockedApplicationGuard,
        },
        {
            path: "/application/:id?/review",
            name: "application-review",
            component: () => import("../views/ReviewView.vue"),
            beforeEnter: notLockedApplicationGuard,
        },
        {
            path: "/online-payment",
            name: "online-payment",
            component: () => import("../views/UniversalPaymentView.vue"),
        },
        {
            path: "/review-payment/:id",
            name: "review-payment",
            component: () => import("../views/PaymentReviewView.vue"),
        },
        {
            path: "/mun-payment",
            name: "mun-payment",
            component: () => import("../views/MunRegistrationView.vue"),
        },
        {
            path: "/not-found",
            name: "not-found",
            component: () => import("../views/NotFoundView.vue"),
        },
        {
            path: "/payment",
            component: () => import("../views/PaymentWrapperView.vue"),
            children: [
                {
                    path: "success",
                    name: "payment-success",
                    component: () => import("../views/payment/SuccessView.vue"),
                },
                {
                    path: "failure",
                    name: "payment-failure",
                    component: () => import("../views/payment/FailureView.vue"),
                },
                {
                    path: "pending",
                    name: "payment-pending",
                    component: () => import("../views/payment/PendingView.vue"),
                },
                {
                    path: "later",
                    name: "payment-later",
                    component: () => import("../views/payment/LaterView.vue"),
                },
                {
                    path: "abroad",
                    name: "payment-abroad",
                    component: () => import("../views/payment/AbroadView.vue"),
                }
            ]
        }
    ],
});

export default router;
