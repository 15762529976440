class Tracker {
    trackEvent(name: string, data: any): void
    {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': name,
            ...data
        });
    }
}

export const tracker = new Tracker();
